import { Container, Image, Text, Box, Divider, Alert, AlertIcon } from "@chakra-ui/react"
import logo from '../../assets/logo-white.png'

const Header = () => (
    <>
        <Container display={"block"} maxW={'4xl'}>
            <Box display={"flex"} justifyContent={"center"}>
                <Image src={logo} alt={'logo'} maxW={320} />
            </Box>
            <Divider mt={"4"} />
            <Box>
                <Text fontSize={'large'} p={4} textAlign={'center'} fontWeight={'bold'}>
                    CARDÁPIO DA SEMANA
                </Text>
            </Box>
            <Box>
                <Alert background={"gray.200"} borderRadius={"8px"} fontSize={"md"}>
                    <AlertIcon />
                    Todo o nosso cardápio é montado com a supervisão de uma nutricionista e é feito com ingredientes de qualidade e frescos. As marmitas possuem tabela nutricional seguindo a nova Resolução de Diretoria Colegiada - RDC nº 429 e Instrução Normativa nº 75 da ANVISA.
                </Alert>
            </Box>
        </Container>
    </>
)

export default Header