import { ChakraProvider, theme, Grid, VStack, Box } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { Category } from "../../Components/category";
import Header from "../../Components/header";

export const Menu = () => (
  <ChakraProvider theme={theme}>
    <Box fontSize="xl">
      <Grid p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Header />
          <Category title={'Marmitas Congeladas'} category={'marmitas'} />
          <Category title={'Lanches'} category={'lanches'} />
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
)