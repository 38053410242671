import {
    Box, Container, Text, SimpleGrid, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Image,
    LinkBox,
    Badge,
    Heading
} from "@chakra-ui/react";
import { getFirestore, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { FaHeart } from "react-icons/fa";
import app from "../../utils/firebase.service.init";
import { ItemCategory } from "./item";

export type CategoryProps = {
    title: string;
    category: string;
}

export type Item = {
    titulo: string;
    descricao: string;
    preco: number;
    de_preco: number;
    peso?: number;
    foto: string;
    tabela?: string;
    selos?: [string];
}

export const Category = (props: CategoryProps) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [items, setItems] = useState<any[] | null>();
    const [item, setItem] = useState<Item | null>();
    const db = getFirestore(app)

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, 'menus'), where('categoria', '==', props.category));
            onSnapshot(q, (querySnapshot) => {
                const items: any[] = []
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id })
                })
                setItems(items)
            })

        }
        fetchData()
    }, [db, props.category])

    const openDetails = (item: Item) => {
        setItem(item)
        onOpen()
    }

    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Fitgourmet SLZ - Cardápio Semanal',
                text: 'Que tal comer bem e saudável? Confira o cardápio da Fitgourmet SLZ',
                url: 'https://cardapio.fitgourmetslz.com.br/',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent('Que tal comer bem e saudável? Confira o cardápio da Fitgourmet SLZ https://cardapio.fitgourmetslz.com.br/'), "_blank");
        }
    }

    return (
        <Container maxW={"5xl"}>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Box bgImage={item?.foto} bgPos={"center center"} bgSize={"cover"} minH={"300"} display={"flex"} alignItems={"end"}>
                            <Box p={4} bgColor={"whiteAlpha.700"}>
                                <Text>{item?.titulo}</Text>
                            </Box>
                        </Box>
                        {item?.de_preco && (
                            <Box mt={4} textDecoration={"line-through"} fontSize={"md"} color={"red.200"}>
                                De:
                                <CurrencyFormat
                                    value={item?.de_preco}
                                    prefix=" R$ "
                                    displayType={"text"}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                />
                            </Box>
                        )}
                        <Box fontWeight={"bold"}>
                            Por:
                            <CurrencyFormat
                                value={item?.preco}
                                prefix=" R$ "
                                displayType={"text"}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                decimalSeparator={","}
                                thousandSeparator={"."}
                            />
                            {item?.peso && (
                                <Badge background={"greenyellow"} mx={"2"} borderRadius={"full"}>
                                    {item?.peso} gramas
                                </Badge>
                            )}
                            {item?.selos && item.selos?.map((selo) => (
                                <Badge borderRadius={"full"}>
                                    {selo}
                                </Badge>
                            ))}
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{item?.descricao}</Text>
                        {item?.tabela && (
                            <Box>
                                <Heading my={4} as={'h2'} size={"sm"}>Informações nutricionais:</Heading>
                                <Image src={item?.tabela} />
                            </Box>
                        )}
                    </ModalBody>

                    <ModalFooter display={"flex"} alignContent={"center"} justifyContent={"center"}>
                        <Button leftIcon={<FaHeart />} onClick={() => share()} colorScheme='whatsapp' mr={3}>
                            Compartilhe nosso cardápio!
                        </Button>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box>
                <Text fontSize={'x-large'} textTransform={"uppercase"} py={4} fontWeight={'bold'}>
                    {props.title}
                </Text>
            </Box>
            <SimpleGrid minChildWidth={'280px'} spacing={'10px'}>
                {items && items.map((item: Item, index: number) => (
                    <LinkBox onClick={() => openDetails(item)}>
                        <ItemCategory item={item} key={index} />
                    </LinkBox>
                ))}

            </SimpleGrid>

        </Container>
    )
}