import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAOaEp8sbmRE-JQEoXWguWCD2dQaNBKba4",
    authDomain: "fitgourmet-menu.firebaseapp.com",
    projectId: "fitgourmet-menu",
    storageBucket: "fitgourmet-menu.appspot.com",
    messagingSenderId: "950731331366",
    appId: "1:950731331366:web:7384c026f9258b3dbbd3e7",
    measurementId: "G-3H106MDSQK"
  };

  const app = initializeApp(firebaseConfig);
  export default app;