import { Badge, Box, Text } from "@chakra-ui/react"
import CurrencyFormat from "react-currency-format"

export const ItemCategory = (item: any) => {
    return (
        <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
            <Box bgImage={item.item.foto} bgPos={"center center"} bgSize={"cover"} minH={"260"} />
            <Box p='6'>
                <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={2}
                >
                    {item.item.titulo}
                </Box>

                {item.item.de_preco && (
                    <Box textDecoration={"line-through"} fontSize={"md"} color={"red.200"}>
                        <CurrencyFormat
                            value={item.item.de_preco}
                            prefix="R$ "
                            displayType={"text"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator={","}
                            thousandSeparator={"."}
                        />
                    </Box>
                )}

                <Box fontWeight={"bold"}>
                    <CurrencyFormat
                        value={item.item.preco}
                        prefix="R$ "
                        displayType={"text"}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                    />
                    {item.item.peso && (
                        <Badge background={"greenyellow"} mx={"2"} borderRadius={"full"}>
                            {item.item.peso} gramas
                        </Badge>
                    )}
                </Box>

                <Box display='flex' mt='2' noOfLines={4} alignItems='center'>
                    <Text>{item.item.descricao}</Text>
                </Box>
            </Box>
        </Box>
    )
}